import api from '@/javascript/vuejs/services/api'

export default function portalMigration() {
	const configElement = document.getElementById('portal_migration_data')
	const validatePortalMigration = document.getElementById('validate_portal_migration')
	const startPortalMigration = document.getElementById('start_portal_migration')

	if (!configElement || !validatePortalMigration || !startPortalMigration) {
		return
	}

	const config = JSON.parse(configElement.innerHTML)

	validatePortalMigration.addEventListener('click', () => {
		validatePortalMigration.style.display = 'none'
		setPortalMigrationStatus('')
		api.get(config.validation_path)
			.then(response => {
				const data = response.data as Data

				generateMessage(data)

				displayStartMigration('block')
			})
			.catch(error => {
				const response = error.response
				const data = response.data
				const message = generateMessage(data)

				displayStartMigration('none')

				const nextDateString = data.next_step_scheduled_at_datetime

				if (nextDateString) {
					const date = new Date(nextDateString)

					setPortalMigrationStatus(`${message }<br />Next step starts at ${ date}`)
				}
				validatePortalMigration.style.display = 'block'
				if (data.status_name === 'enrollment_error') {
					displayStartMigration('block')
				}
			})
	})

	startPortalMigration.addEventListener('click', () => {
		displayStartMigration('none')
		if (!confirm('Are you sure you want to migrate this agency\'s LU sub portal to the agency franchisor\'s LU sub portal?')) {
			setPortalMigrationStatus('')
			validatePortalMigration.style.display = 'block'
			return
		}
		api.post(config.start_migration_path, {})
			.then(response => {
				const data = response.data as Data

				setPortalMigrationStatus(data.message)
				validatePortalMigration.style.display = 'block'
			})
			.catch(error => {
				const response = error.response
				const data = response.data

				setPortalMigrationStatus(data.message)
				validatePortalMigration.style.display = 'block'
			})
	})
}

function generateMessage(data: Data) {
	const status = data.status_name
	const errors = data.errors || []
	let message = status ? `Migration Status: ${status}` : ''

	if (errors.length > 0) {
		message = `${message }<br /><div style="color: #ae4624"><strong>Problems</strong> <br />${errors.join('<br />')}</div>`
	}

	if (message) {
		setPortalMigrationStatus(message)
	}

	return message
}

function setPortalMigrationStatus(message: string) {
	const portalMigrationStatus = document.getElementById('portal_migration_status')

	if (portalMigrationStatus) {
		portalMigrationStatus.innerHTML = message
	}
}

function displayStartMigration(style: string) {
	const element = document.getElementById('start_portal_migration')

	if (element) { element.style.display = style }
}

interface Data {
	errors: string[]
	message: string
	next_step_scheduled_at_datetime: string
	status_name: string
}
