/* eslint-disable @typescript-eslint/explicit-function-return-type */
import $ from 'jquery'

import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

export default function initPopulateOrganizationIntegrationIds() {
	$('#back-office-credential-id').change(function () {
		const populateOrganizationIntegrationIdsUrl = 'populate_organization_integration_ids'
		const backOfficeCredentialId = $('#back-office-credential-id').val()

		if (backOfficeCredentialId) {
			$.ajax({
				beforeSend: function (xhr) {
					xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
				},
				data: { back_office_credential_id: backOfficeCredentialId },
				dataType: 'json',
				type: 'POST',
				url: populateOrganizationIntegrationIdsUrl,
			})
				.done(function (response) {
					const dataList = $('#org_ids')

					// First remove options from dropdown, otherwise options could potentially get repopulated several times, resulting in multiple duplicates
					dataList.find('option').remove()
					const organizationIntegrationIds = response.org_ids

					for (let i = 0; i < organizationIntegrationIds.length; i++) {
						$('<option />', {
							text: organizationIntegrationIds[i],
							value: organizationIntegrationIds[i],
						}).appendTo(dataList)
					}
				})
				.fail(function (response) {
					GlobalStore.onHandleErrorMessages({ error: response.responseJSON.error })
				})
		}
	})
}
