import { CAButton } from '@careacademy/ca-design-system'
import Vue, { defineComponent } from 'vue'
import VModal from 'vue-js-modal'

Vue.use(VModal, {
	dynamic: true,
	injectModalsContainer: true,
})

import createDialog from '@/javascript/components/dialog'
import type { ViewOption } from '@/javascript/interfaces'
import api from '@/javascript/vuejs/services/api'
import {
	deleteContentPackageFolders,
	getContentPackageFolders,
	getContentPackages,
} from '@/javascript/vuejs/services/packages'
import {
	CONTENT_PACKAGE,
	CONTENT_PACKAGE_FOLDER,
	type ContentPackageFolder,
	type PackageType,
} from '@/javascript/vuejs/services/packages/interfaces'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'
import PackagesAndProgramsStore from '@/javascript/vuejs/stores/PackagesAndProgramsStore'
import { pluralize } from '@/javascript/vuejs/utils/stringUtils'

import InputSearch from '../shared/InputSearch'
import ListViewFolderView from '../shared/ListViewFolderView'
import LoadingIcon from '../shared/LoadingIcon'
import type { CheckedNodes } from '../shared/TreeView'
import FolderViewPackage from './FolderViewPackage'
import ListViewPackage from './ListViewPackage'
import CreateFolderModal from './modal-content/CreateFolder'
import MoveIntoFolder from './modal-content/MoveIntoFolder'
import RenameFolder from './modal-content/RenameFolder'
import { stringToNumber } from './utils'

function flattenFolder(array: ContentPackageFolder[]): ContentPackageFolder[] {
	return array.flatMap((item: ContentPackageFolder) => item.children ? [item, ...flattenFolder(item.children)] : item)
}

export default defineComponent({
	name: 'ContentPackages',

	data(): Data {
		return {
			PackagesAndProgramsStore,
			action: null,
			checkedPackagesAndFolders: [],
			deleteFolders: [],
			foldersAndPackages: [],
			hideBuildingBlocks: true,
			isBuildingBlock: false,
			loading: false,
			parentFolderId: undefined,
			renameFolder: undefined,
			renamedFolderName: '',
			searchInput: undefined,
			selectedItems: [],
			showView: 'list',
			sortBy: undefined,
			sortDirection: undefined,
		}
	},

	computed: {
		currentFolder(): ContentPackageFolder | undefined {
			const selectedFolder = PackagesAndProgramsStore.allFolders.find(folder => folder.id === this.parentFolderId)

			if (!selectedFolder) {
				return
			}

			return {
				...selectedFolder,
				name: !!this.renamedFolderName ? this.renamedFolderName : selectedFolder.name,
			}
		},

		folders(): ContentPackageFolder[] {
			return flattenFolder(PackagesAndProgramsStore.allAvailablePackagesAndFolders)
				.filter(f => f.type === 'content_package_folder')
				.map(f => ({
					hasChildren: !!f.children?.length,
					id: f.id,
					level: f.level ?? 0,
					name: f.name,
					type: f.type,
				}))
				.sort((a, b) => a.name.toLowerCase()?.localeCompare(b.name.toLowerCase()))
		},

		hasFoldersToDelete(): boolean {
			return !!this.deleteFolders.length
		},

		hasFoldersToPackagesOrFoldersToMove(): boolean {
			return !!this.selectedItems.length
		},

		isFolderView(): boolean {
			return this.showView === 'folder'
		},

		isListView(): boolean {
			return this.showView === 'list'
		},

		numberOfFolders(): number {
			return this.foldersAndPackages.filter(folder => folder.type === 'content_package_folder').length
		},

		selectedFolder(): ContentPackageFolder | undefined {
			const selectedFolderId = PackagesAndProgramsStore.selectedFolder?.id

			if (!PackagesAndProgramsStore.selectedFolder || !selectedFolderId) {
				return
			}

			return { ...PackagesAndProgramsStore.selectedFolder, id: selectedFolderId }
		},

		showBuildingBlocks(): boolean | undefined {
			if (this.hideBuildingBlocks) {
				return false
			} else if (this.isBuildingBlock) {
				return this.isBuildingBlock
			} else {
				return undefined
			}
		},

		showBulkOptions(): boolean {
			return !!this.checkedPackagesAndFolders && !!this.checkedPackagesAndFolders.length
		},
	},

	watch: {
		showView() {
			if (this.isFolderView) {
				this.refreshFolders()
			}
		},
	},

	mounted() {
		this.getContentPackages()
	},

	methods: {
		bulkDeleteFolders(): void {
			this.openDialog()
		},

		bulkMoveIntoFolder(): void {
			this.selectedItems = this.checkedPackagesAndFolders

			this.$nextTick(() => {
				this.$modal.show('move-into-folder-modal')
			})
		},

		createFolderForParent(): void {
			if (this.selectedFolder && typeof this.selectedFolder.id === 'string') {
				this.parentFolderId = stringToNumber(this.selectedFolder.id)
			}

			this.$nextTick(() => {
				this.$modal.show('create-content-package-folder-modal')
			})
		},

		async deletePackageFolders(): Promise<void> {
			if (!this.hasFoldersToDelete) {
				GlobalStore.onHandleErrorMessages({ error: 'Nothing to delete. Please select a folder and try again.' })
				return
			}

			for (const i of this.deleteFolders) {
				const item = PackagesAndProgramsStore.allAvailablePackagesAndFolders.find(f => f.id === i.id && f.type === i.type)

				if (!item) {
					GlobalStore.onHandleErrorMessages(
						{ error: 'Unable to find folder to delete. Please refresh and try again, or contact customer support for assistance.' }
					)
				}

				if (item && item?.type === CONTENT_PACKAGE) {
					GlobalStore.onHandleErrorMessages(
						{ error: 'Deleting of content packages are not allowed to be deleted here, and have been skipped. Please contact customer support for further assistance.' }
					)

					if (this.deleteFolders.length === 1) {
						return
					}

					this.deleteFolders = this.deleteFolders.splice(0, 1, item)
				}

				if (item?.hasChildren) {
					GlobalStore.onHandleErrorMessages(
						{ error: `Unable to delete a folder that is not empty. ${item.name} was not deleted and has been skipped.` }
					)

					if (this.deleteFolders.length === 1) {
						return
					}

					this.deleteFolders = this.deleteFolders.splice(0, 1, item)
				}
			}

			try {
				await deleteContentPackageFolders(api, this.deleteFolders.map(i => stringToNumber(i.id ?? '')))
				GlobalStore.onHandleSuccessMessages({ message: 'Selected content package folders have been deleted' })

				this.refreshFolders()
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: error.response.data.message })
			}
		},

		async getContentPackageFolders(id?: number | string): Promise<void> {
			this.loading = true

			try {
				const response = await getContentPackageFolders(api, {
					include_all: true,
					parent_folder_id: !!id ? stringToNumber(id) : undefined,
				})

				const filteredResponse = response.filter(item => !!item.name)

				PackagesAndProgramsStore.allAvailablePackagesAndFolders = flattenFolder(filteredResponse)
					.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

				this.foldersAndPackages = filteredResponse
					.sort((a, b) =>
						a.type > b.type ? -1 : a.type < b.type ? 1 : 0 ||
							a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					)

				if (!id) {
					PackagesAndProgramsStore.selectedFolder = undefined
				}
			} catch (error) {
				GlobalStore.onHandleErrorMessages({ error })
			} finally {
				this.loading = false
			}
		},

		async getContentPackages(event?: Event): Promise<void> {
			event?.preventDefault()

			if (this.isListView) {
				this.loading = true

				try {
					PackagesAndProgramsStore.contentPackages = await getContentPackages(api, {
						direction: this.sortDirection,
						format: 'json',
						is_building_block: this.showBuildingBlocks,
						search: this.searchInput,
						sort: this.sortBy,
					})
				} catch (error) {
					console.error(error)
				} finally {
					this.loading = false
				}
			}
		},

		handleAction(action: string, id: number | string | undefined, type?: PackageType): void {
			if (type === CONTENT_PACKAGE_FOLDER && id) {
				this.parentFolderId = id
			}

			if (action === 'Move') {
				PackagesAndProgramsStore.selectedFolder = this.folders.find(folder => folder.id === id && folder.type === type)
			}

			switch (action) {
				case 'Create':
					this.createFolderForParent()
					break

				case 'Delete':
					this.deleteFolders.push({ id, type })
					this.openDialog()
					break

				case 'Move':
				case 'Move Package':
					this.moveIntoFolder(action, id, type)
					break

				case 'Rename':
					this.renameContentPackageFolder()
					break
			}
		},

		handleCancelMove(): void {
			this.selectedItems = []
		},

		handleCheckedItems(checkedNodes: CheckedNodes[]): void {
			this.checkedPackagesAndFolders = checkedNodes
			this.deleteFolders = this.checkedPackagesAndFolders
		},

		handleFolderMove(id: number): void {
			this.parentFolderId = id
			PackagesAndProgramsStore.selectedFolder = PackagesAndProgramsStore.allFolders.find(folder => folder.id === this.parentFolderId)

			this.refreshFolders()
		},

		handleFolderRename(id: number, name: string): void {
			this.parentFolderId = id
			this.renamedFolderName = name
			this.refreshFolders()
		},

		handleKeypress(key: KeyboardEvent): undefined | void {
			this.$nextTick(() => {
				if (key.key === 'Enter') {
					this.getContentPackages()
				}
			})
		},

		hidePackagesBuildingBlocks(): void {
			this.isBuildingBlock = false
			this.$nextTick(() => {
				this.getContentPackages()
			})
		},

		moveIntoFolder(action: string, id: number | string | undefined, type?: string): void {
			const selectedItem = PackagesAndProgramsStore.allAvailablePackagesAndFolders.find(f => f.id === id && f.type === type)

			if (!selectedItem) {
				return
			}

			this.selectedItems?.push({
				id: selectedItem.id,
				name: selectedItem.name,
				type: selectedItem.type,
			})

			this.$nextTick(() => {
				this.$modal.show('move-into-folder-modal')
			})
		},

		openDialog(): void {
			const message = this.selectedItems.length === 1 ?
				'Confirm that this is a folder and that it is empty before deleting' :
				'Confirm that only folders are selected and that the folders are empty before deleting'

			createDialog({
				bodyText: message,
				confirmButtonText: 'Delete',
				dialogSelector: '#confirmation-dialog',
				titleText: `Are you sure you want to delete?`,
			} as any).then(
				() => this.deletePackageFolders(),
				() => { }
			)
		},

		refreshFolders(): void {
			this.deleteFolders = []
			this.parentFolderId = undefined
			this.renameFolder = undefined
			this.renamedFolderName = ''
			this.selectedItems = []

			this.getContentPackageFolders()
			PackagesAndProgramsStore.getAllContentPackageFolders()
		},

		renameContentPackageFolder(): void {
			this.renameFolder = this.currentFolder

			this.$nextTick(() => {
				this.$modal.show('rename-content-package-folder-modal')
			})
		},

		showPackagesBuildingBlocks(): void {
			this.hideBuildingBlocks = false
			this.$nextTick(() => {
				this.getContentPackages()
			})
		},
	},

	render() {
		return (
			<div>
				<div class="admin__heading-container">
					<h1 class="admin__heading">Content Packages</h1>

					{this.isListView &&
						<span class="self-end">
							<CAButton
								dataTest="content-packages-new-link"
								onClick={(): void => window.location.replace('/packages/new')}
								type="primary"
							>
								New package
							</CAButton>

							<CAButton
								dataTest="content-packages-download-link"
								onClick={(): void => window.location.replace('/packages/csv')}
								type="primary"
							>
								Download packages report
							</CAButton>
						</span>
					}

					<CreateFolderModal parent-folder-id={this.parentFolderId} onCreated={this.refreshFolders} />
				</div>

				{!this.loading &&
					<div class="flex-container flex-container--space-between">
						<ListViewFolderView onShow={(view: ViewOption): string => this.showView = view} />

						{this.showBulkOptions &&
							<p>
								<span>
									<CAButton
										class="ml-2"
										dataTest="content-packages-bulk-move"
										onClick={this.bulkMoveIntoFolder}
									>
										Move Folder(s)
									</CAButton>

									{this.hasFoldersToDelete &&
										<CAButton
											class="ml-2"
											dataTest="content-packages-bulk-delete"
											type="danger"
											onClick={this.bulkDeleteFolders}
										>
											Delete Folder(s)
										</CAButton>
									}
								</span>
							</p>
						}
					</div>
				}

				<hr class="clear clearfix" />

				{this.isListView &&
					<div>
						<div class="form-inline">
							<div class="form-check">
								<input
									v-model={this.isBuildingBlock}
									id="is_building_block"
									data-test="building-block-checkbox"
									name="is_building_block"
									type="checkbox"
									onChange={this.showPackagesBuildingBlocks}
								/>
								<label for="is_building_block">Building Blocks</label>
							</div>

							<div class="form-check ml-2">
								<input
									v-model={this.hideBuildingBlocks}
									id="hide_building_block"
									data-test="hide-building-block-checkbox"
									name="hide_building_block"
									type="checkbox"
									onChange={this.hidePackagesBuildingBlocks}
								/>
								<label for="hide_building_block">Hide Building Blocks</label>
							</div>

							<InputSearch
								v-model={this.searchInput}
								additionalClasses="form-control w-50"
								dataTest="search-input"
								placeholderText="Search by package name"
							/>

							<CAButton
								buttonStyle="outlined"
								data-disable-with="Filter"
								dataTest="content-packages-filter-button"
								type="primary"
								onClick={this.getContentPackages}
							>
								Filter
							</CAButton>
						</div>

						<hr class="clear clearfix" />
					</div>
				}

				{this.loading ?
					<LoadingIcon />

					:

					<div>
						{this.isFolderView &&
							<div>
								{!this.foldersAndPackages.length ?
									<div class="container__no-results" data-test="content-packages-no-results">
										<p>Currently no folders or packages to display.</p>
									</div>

									:

									<div>
										<FolderViewPackage
											folders={this.foldersAndPackages}
											onAction={(action: string, id: number, type?: PackageType): void =>
												this.handleAction(action, id, type)}
											onBulk={(checkedNodes: CheckedNodes[]): void => this.handleCheckedItems(checkedNodes)}
										/>

										{this.hasFoldersToPackagesOrFoldersToMove &&
											<MoveIntoFolder
												folders={this.folders}
												items={this.selectedItems}
												onCancel={this.handleCancelMove}
												onMove={(id: number): void => this.handleFolderMove(id)}
											/>
										}

										{this.folders && this.renameFolder &&
											<RenameFolder
												folder={this.renameFolder}
												onCancel={(): undefined => this.renameFolder = undefined}
												onRenamed={(id: number, name: string): void => this.handleFolderRename(id, name)}
											/>
										}
									</div>
								}
							</div>
						}

						{this.isListView &&
							<ListViewPackage onAction={(action: string): string => this.action = action} />
						}

						<p>
							Displaying <b>all {PackagesAndProgramsStore.contentPackages.length}</b> packages
							{this.isFolderView && <span> and <b>{this.numberOfFolders}</b> {pluralize(this.numberOfFolders, 'folder')}</span>}
						</p>
					</div>
				}
			</div>
		)
	},
})

interface Data {
	PackagesAndProgramsStore: typeof PackagesAndProgramsStore
	action: string | null
	checkedPackagesAndFolders: CheckedNodes[]
	deleteFolders: CheckedNodes[]
	foldersAndPackages: ContentPackageFolder[]
	hideBuildingBlocks: boolean
	isBuildingBlock: boolean | undefined
	loading: boolean
	parentFolderId: number | string | undefined
	renameFolder: ContentPackageFolder | undefined
	renamedFolderName: string
	searchInput: string | undefined
	selectedItems: CheckedNodes[]
	showView: ViewOption
	sortBy: 'name' | undefined
	sortDirection: 'asc' | 'desc' | undefined
}
