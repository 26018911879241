import { CAButton } from '@careacademy/ca-design-system'
import { defineComponent } from 'vue'

import ModalCloseButton from '@/javascript/vuejs/components/common/ModalCloseButton'
import StandardModalButtonContainer from '@/javascript/vuejs/components/common/StandardModalButtonContainer'
import InputDefault from '@/javascript/vuejs/components/shared/InputDefault'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { createFolder } from '@/javascript/vuejs/services/packages'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

const emits = { 'created': (): boolean => true }

export default defineComponent({
	name: 'CreatePackageFolderModal',

	props: {
		...on(emits),

		parentFolderId: { optional: true, type: Number },
	},

	emits,

	data(): Data {
		return {
			createFolderName: '',
			isProcessing: false,
		}
	},

	computed: {
		isDisabled(): boolean {
			return !this.createFolderName
		},

		modalName(): string {
			return 'create-content-package-folder-modal'
		},
	},

	methods: {
		closeModal(): void {
			this.$modal.hide(this.modalName)
		},

		async createContentPackageFolder(): Promise<void> {
			if (!this.createFolderName) {
				return
			}

			this.isProcessing = true
			try {
				await createFolder(api, {
					name: this.createFolderName,
					parent_content_package_folder_id: this.parentFolderId ?? null,
				})
				this.closeModal()
				this.$emit('created')
				this.createFolderName = ''
				GlobalStore.onHandleSuccessMessages({ message: `Content package folder, named "${this.createFolderName}" created`})
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: error.message })
			} finally {
				this.isProcessing = false
				GlobalStore.onHandleSuccessMessages({ message: `Content package folder, named "${this.createFolderName}" created`})
			}
		},

		showModal(): void {
			this.$modal.show(this.modalName)
		},
	},

	render() {
		return (
			<div class="create-content-package-folder-content">
				<CAButton
					buttonStyle="filled-light"
					dataTest="content-packages-new-folder"
					loading={this.isProcessing}
					onClick={this.showModal}
				>
					Create package folder
				</CAButton>

				<modal
					height="auto"
					name={this.modalName}
					scrollable={false}
					width="500px"
				>
					<div
						class={[
							`${this.modalName}`,
							'simple-modal',
							'simple-modal__flex-container',
							'simple-modal__has-button-container',
						]}
						data-test={this.modalName}
					>
						<div class="content">
							<div class="simple-modal__container">
								<ModalCloseButton onClose={this.closeModal} />

								<h2 class="simple-modal__header">Create <span>Package Folder</span></h2>
							</div>

							<div class="simple-modal__section">
								<InputDefault
									v-model={this.createFolderName}
									autofocus
									dataTest="create-new-content-package-folder"
									error="Folder Name is required"
									id="newFolder"
									labelText="Folder Name"
									name="new_folder"
									required={true}
									onEnter={this.createContentPackageFolder}
								/>
							</div>
						</div>

						<StandardModalButtonContainer
							actionButtonText="Create"
							isDisabled={this.isDisabled}
							modalName={this.modalName}
							onClosed={this.closeModal}
							onSave={this.createContentPackageFolder}
						/>
					</div>
				</modal>
			</div>
		)
	},
})

interface Data {
	createFolderName: string
	isProcessing: boolean
}
