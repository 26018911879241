/* eslint-disable @typescript-eslint/explicit-function-return-type */
import $ from 'jquery'

import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

export default function initConfirmationDialog() {
	$('#create-agency_mgr, #create-franchisor_mgr').on('click', function (event) {
		let $form

		if (event.target.id === 'create-agency_mgr') {
			$form = $('#new_administrator')
		} else {
			$form = $('#new_user')
		}

		if ($form[0].checkValidity() === false) {
			return
		}

		event.preventDefault()

		// Send verification request before adding a new user/role
		$.ajax({
			data: $form.serializeArray(),
			headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
			method: 'POST',
			url: $form.data('verification-url'),
		})
			.done(function (data, textStatus, xhr) {
				switch (xhr.status) {
					case 200:
						if (confirm(data.message) === true) {
							createNewUserRole($form)
						}
						break

					case 204:
						createNewUserRole($form)
						break

					default:
						GlobalStore.onHandleErrorMessages({ error: error.responseJSON.message })
				}
			})
			.fail(function (error) {
				GlobalStore.onHandleErrorMessages({ error: error.responseJSON.message })
			})
	})

	function createNewUserRole($form) {
		$.ajax({
			data: $form.serializeArray(),
			headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
			method: 'POST',
			url: $form.data('invite-url'),
		})
			.done(function () {
				// flash message is sent by rails controller
				window.location.href = $form.data('redirect-url')
			})
			.fail(function (error) {
				GlobalStore.onHandleErrorMessages({ error: error.responseJSON.message })
			})
	}
}
