import { defineComponent, type PropType } from 'vue'

import ModalCloseButton from '@/javascript/vuejs/components/common/ModalCloseButton'
import StandardModalButtonContainer from '@/javascript/vuejs/components/common/StandardModalButtonContainer'
import SelectDropdown from '@/javascript/vuejs/components/shared/SelectDropdown'
import type { CheckedNodes } from '@/javascript/vuejs/components/shared/TreeView'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { moveContentPackageOrFolder } from '@/javascript/vuejs/services/packages/'
import type { ContentPackageFolder } from '@/javascript/vuejs/services/packages/interfaces'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'
import PackagesAndProgramsStore from '@/javascript/vuejs/stores/PackagesAndProgramsStore'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'cancel': (): boolean => true,
	'move': (id: number | null): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'MoveIntoFolderModal',

	props: {
		...on(emits),

		folders: {
			required: true,
			type: Array as PropType<ContentPackageFolder[]>,
		},

		items: {
			required: true,
			type: Array as PropType<CheckedNodes[]>,
		},
	},

	emits,

	data(): Data {
		return { destinationFolderId: null }
	},

	methods: {
		closeModal(): void {
			this.$modal.hide('move-into-folder-modal')
			this.destinationFolderId = null
			this.$emit('cancel')
		},

		async moveContentPackageOrFolder(): Promise<void> {
			if (!this.items.length) {
				GlobalStore.onHandleErrorMessages({ error: 'Nothing to move. Please select item(s) and try again' })
				return
			}

			try {
				await moveContentPackageOrFolder(api, {
					id: this.destinationFolderId,
					items: this.items,
				})
				this.$emit('move', this.destinationFolderId)
				this.closeModal()
				GlobalStore.onHandleSuccessMessages({ message: 'Selected content package(s)/folder(s) have been moved' })
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: error.message })
			}
		},

		onHandleChange(id: string): void {
			this.destinationFolderId = parseInt(id)
		},

		showModal(): void {
			this.$modal.show('move-into-folder-modal')
		},
	},

	render() {
		return (
			<div class="move-into-folder-content">
				<modal
					height="auto"
					name="move-into-folder-modal"
					scrollable={false}
					width="466px"
				>
					<div
						class={[
							'move-into-folder-modal',
							'simple-modal',
							'simple-modal__flex-container',
							'simple-modal__has-button-container',
						]}
						data-test="move-into-folder-modal"
					>
						<div class="content">
							<div class="simple-modal__container">
								<ModalCloseButton onClose={this.closeModal} />

								<h2 class="simple-modal__header">Move <span>Into Folder</span></h2>
							</div>

							<div class="simple-modal__section">
								<SelectDropdown
									additionalClasses="form-control select"
									id="destination-folder-id"
									dataTest="destination-folder-select"
									labelText="Select folder to move package(s) or folder(s)"
									theme="isSimple"
									hasTooltip={false}
									required={true}
									value={`${this.destinationFolderId}`}
									onChange={(id: string): void => this.onHandleChange(id)}
								>
									<option value="" selected>No folder selected</option>
									{PackagesAndProgramsStore.allFolders.map(folder =>
										<option
											key={`${folder.level_name ?? folder.id}`}
											value={folder.id}
										>
											{folder.level_name ?? folder.name}
										</option>
									)}
								</SelectDropdown>
							</div>
						</div>

						<StandardModalButtonContainer
							actionButtonText="Move"
							modalName="move-into-folder-modal"
							onClosed={this.closeModal}
							onSave={this.moveContentPackageOrFolder}
						/>
					</div>
				</modal>
			</div>
		)
	},
})

interface Data {
	destinationFolderId: number | null
}
