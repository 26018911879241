import Rails from '@rails/ujs'
import $ from 'jquery'

import bootstrapNavbar from '@/javascript/components/bootstrap_navbar'
import caregiverDashboardEnabledCheckbox from '@/javascript/components/caregiver_dashboard_enabled_checkbox'
import initConfirmationDialog from '@/javascript/components/confirmation_dialog'
import initEitherOr from '@/javascript/components/either_or'
import initFlashMessages from '@/javascript/components/flash'
import initImageUpload from '@/javascript/components/image_upload'
import initMultiSelector from '@/javascript/components/multi_selector'
import initPopulateOrganizationIntegrationIds from '@/javascript/components/populate_organization_integration_ids'
import portalMigration from '@/javascript/components/portal_migration'
import portalMigrationToMainPortal from '@/javascript/components/portal_migration_to_main_portal'
import requirePublicNameForVisibleCurriculum from '@/javascript/components/require_public_name_for_visible_curriculum'
import initShowPasswordCheckbox from '@/javascript/components/show_password_checkbox'
import showPortalMigrationStatus from '@/javascript/components/show_portal_migration_status'
import initTooltips from '@/javascript/components/tippy_tooltips'
import contentPackagesApp from '@/javascript/vuejs/pages/ContentPackagesPage'
import notificationWrapperApp from '@/javascript/vuejs/pages/NotificationWrapper'
import packageManagementApp from '@/javascript/vuejs/pages/PackageManagementPage'
import programManagementApp from '@/javascript/vuejs/pages/ProgramManagementPage'
// Load vue apps if element is on the page
const apps = [
	{ loader: programManagementApp, name: 'programManagementApp' },
	{ loader: contentPackagesApp, name: 'contentPackages' },
	{ loader: notificationWrapperApp, name: 'notificationWrapperApp' },
	{ loader: packageManagementApp, name: 'packageManagementApp' },
]

for (const app of apps) {
	const element = document.querySelector(`#${app.name}`)

	if (element) {
		app.loader()
	}
}

$(document).ready(function () {
	bootstrapNavbar()
	caregiverDashboardEnabledCheckbox()
	initFlashMessages()
	initTooltips()
	initEitherOr()
	initImageUpload()
	initMultiSelector()
	initConfirmationDialog()
	initPopulateOrganizationIntegrationIds()
	initShowPasswordCheckbox()
	portalMigration()
	portalMigrationToMainPortal()
	showPortalMigrationStatus()
	requirePublicNameForVisibleCurriculum()

	if (!window._rails_loaded) { Rails.start() }
})
