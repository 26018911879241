import { defineComponent, type PropType } from 'vue'

import ModalCloseButton from '@/javascript/vuejs/components/common/ModalCloseButton'
import StandardModalButtonContainer from '@/javascript/vuejs/components/common/StandardModalButtonContainer'
import InputDefault from '@/javascript/vuejs/components/shared/InputDefault'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { renameContentPackageFolder } from '@/javascript/vuejs/services/packages/'
import type { ContentPackageFolder } from '@/javascript/vuejs/services/packages/interfaces'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

import { stringToNumber } from '../utils'
/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'cancel': (): boolean => true,
	'renamed': (id: number, value: string): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'RenamePackageFolderModal',

	props: {
		...on(emits),

		folder: {
			required: true,
			type: Object as PropType<ContentPackageFolder>,
		},
	},

	emits,

	data(): Data {
		return {
			isProcessing: false,
			renameFolderName: this.folder.name,
		}
	},

	computed: {
		isDisabled(): boolean {
			return !this.renameFolderName
		},

		modalName(): string {
			return 'rename-content-package-folder-modal'
		},
	},

	methods: {
		closeModal(): void {
			this.$modal.hide(this.modalName)
			this.renameFolderName = ''
			this.$emit('cancel')
		},

		async renameContentPackageFolder(): Promise<void> {
			if (!this.renameFolderName) {
				return
			}

			const folderId = typeof this.folder.id === 'string' ? stringToNumber(this.folder.id) : this.folder.id

			this.isProcessing = true

			if (folderId) {
				try {
					await renameContentPackageFolder(api, {
						id: folderId,
						name: this.renameFolderName,
					})
					this.$emit('renamed', folderId, this.renameFolderName)
					GlobalStore.onHandleSuccessMessages({ message: `"${this.folder.name}" renamed to "${this.renameFolderName}"` })
					this.closeModal()
				} catch (error: any) {
					GlobalStore.onHandleErrorMessages({ error: error.message })
				} finally {
					this.isProcessing = false
				}
			}
		},
	},

	render() {
		return (
			<div class="rename-content-package-folder-content">
				<modal
					height="auto"
					name={this.modalName}
					scrollable={false}
					width="500px"
				>
					<div
						class={[
							`${this.modalName}`,
							'simple-modal',
							'simple-modal__flex-container',
							'simple-modal__has-button-container',
						]}
						data-test={this.modalName}
					>
						<div class="content">
							<div class="simple-modal__container">
								<ModalCloseButton onClose={this.closeModal} />

								<h2 class="simple-modal__header">
									Rename <span>"{this.folder.name}"</span>
								</h2>
							</div>

							<div class="simple-modal__section">
								<InputDefault
									v-model={this.renameFolderName}
									autofocus
									dataTest="rename-content-package-folder"
									error="Folder Name is required"
									id="renameFolder"
									labelText="Folder Name"
									name="rename_folder"
									required={true}
									onEnter={this.renameContentPackageFolder}
								/>
							</div>
						</div>

						<StandardModalButtonContainer
							actionButtonText="Rename"
							isDisabled={this.isDisabled}
							modalName={this.modalName}
							onClosed={this.closeModal}
							onSave={this.renameContentPackageFolder}
						/>
					</div>
				</modal>
			</div>
		)
	},
})

interface Data {
	isProcessing: boolean
	renameFolderName: string
}
